import { useMemo } from 'react';

import { useRouter } from 'next/router';

import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { Box, Typography } from '@mui/material';

import useCheckDevice from 'hooks/useCheckDevice';

import type { ServerMatchListDataType } from 'types/matchData';

import { dayFormatter } from 'utils/dayFormatter';

const MatchBanner = ({
  matchListData,
  isPlayable,
}: {
  matchListData: ServerMatchListDataType;
  isPlayable: boolean;
}) => {
  const { locale } = useRouter();
  const { isMobile } = useCheckDevice();

  const {
    awayTeamName,
    awayTeamScore,
    date,
    dateAtUtc,
    homeTeamName,
    homeTeamScore,
    sportType,
    startTime,
    status,
  } = useMemo(() => matchListData, [matchListData]);

  const teamA = useMemo(() => {
    if (sportType === 'baseball')
      return { name: awayTeamName, score: awayTeamScore };
    else return { name: homeTeamName, score: homeTeamScore };
  }, [awayTeamName, awayTeamScore, homeTeamName, homeTeamScore, sportType]);

  const teamB = useMemo(() => {
    if (sportType === 'baseball')
      return { name: homeTeamName, score: homeTeamScore };
    else return { name: awayTeamName, score: awayTeamScore };
  }, [awayTeamName, awayTeamScore, homeTeamName, homeTeamScore, sportType]);

  const textEllipsisStyle = {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflowWrap: 'anywhere',
    wordBreak: 'break-all',
  };

  // const scoreTextVariant =
  //   (typeof teamA.score === 'number' && teamA.score > 99) ||
  //   (typeof teamB.score === 'number' && teamB.score > 99)
  //     ? 'chip'
  //     : isMobile
  //     ? 'h4Bold'
  //     : 'h5Bold';

  return status === 'schedule' ? (
    <Box
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        color: 'white',
        width: '100%',
        height: '100%',
        aspectRatio: '16 / 9',
        position: 'absolute',
        top: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 0.5,
        textAlign: 'center',
        px: 4,
        backdropFilter: 'blur(5px)',
      }}
    >
      {locale === 'ko' ? (
        <Typography
          variant={isMobile ? 'h5Bold' : 'subtitle1Bold'}
          sx={{ display: 'flex', flexDirection: 'row' }}
        >
          <Box sx={{ ...textEllipsisStyle, minWidth: '44px' }}>
            {teamA.name}
          </Box>
          <Box sx={{ mx: 1 }}>vs</Box>
          <Box sx={{ ...textEllipsisStyle, minWidth: '44px' }}>
            {teamB.name}
          </Box>
        </Typography>
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant={isMobile ? 'h5Bold' : 'subtitle1Bold'}
            sx={textEllipsisStyle}
          >
            {teamA.name}
          </Typography>
          <Typography
            variant={isMobile ? 'h5' : 'overline'}
            sx={{ color: 'white' }}
          >
            vs
          </Typography>
          <Typography
            variant={isMobile ? 'h5Bold' : 'subtitle1Bold'}
            sx={textEllipsisStyle}
          >
            {teamB.name}
          </Typography>
        </Box>
      )}

      <Typography
        variant={isMobile ? 'h6' : 'body1'}
        sx={{ color: 'deprecatedGray.70' }}
      >
        {dateAtUtc
          ? dayFormatter(dateAtUtc, 'YYYY.MM.DD(ddd) HH:mm', {
              locale: locale,
              isZuluTime: true,
            })
          : dayFormatter(date, `MM.DD (ddd) ${startTime}`, { locale: locale })}
      </Typography>
    </Box>
  ) : (
    <>
      {isPlayable && (
        <PlayArrowRoundedIcon
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            height: '64px',
            width: '70px',
            color: 'white',
            opacity: 0.8,
            filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.8))',
          }}
        />
      )}

      {/* <Grid
        container
        sx={{
          width: '100%',
          height: { xs: '20%', sm: '36px' },
          position: 'absolute',
          aspectRatio: '16 / 9',
          bottom: 0,
          backgroundColor:
            status === 'live' ? 'rgba(140, 0, 2, 0.8)' : 'rgba(0, 0, 0, 0.8)',
          alignItems: 'center',
          textAlign: 'center',
          color: 'white',
          backdropFilter: 'blur(5px)',
        }}
      >
        <Grid item xs={4.5}>
          <Typography
            variant={isMobile ? 'body1Bold' : 'body1'}
            sx={{ ...textEllipsisStyle, px: 1.5 }}
          >
            {teamA.name}
          </Typography>
        </Grid>
        <Grid item xs={1.3}>
          <Typography
            variant={scoreTextVariant}
            sx={{
              ...textEllipsisStyle,
              letterSpacing: { xs: 0, sm: 2 },
              whiteSpace: 'nowrap',
            }}
          >
            {teamA.score ?? '0'}
          </Typography>
        </Grid>
        <Grid
          item
          xs={0.4}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant={scoreTextVariant}>:</Typography>
        </Grid>
        <Grid item xs={1.3}>
          <Typography
            variant={scoreTextVariant}
            sx={{
              ...textEllipsisStyle,
              letterSpacing: { xs: 0, sm: 2 },
              whiteSpace: 'nowrap',
            }}
          >
            {teamB.score ?? '0'}
          </Typography>
        </Grid>
        <Grid item xs={4.5}>
          <Typography
            variant={isMobile ? 'body1Bold' : 'body1'}
            sx={{ ...textEllipsisStyle, px: 1.5 }}
          >
            {teamB.name}
          </Typography>
        </Grid>
      </Grid> */}
    </>
  );
};

export default MatchBanner;
