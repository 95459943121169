import useTranslation from 'next-translate/useTranslation';

import type { ChipProps } from '@mui/material';
import { Chip, Typography } from '@mui/material';

const LiveChip = ({ sx, ...props }: ChipProps) => {
  const { t } = useTranslation('common');

  return (
    <Chip
      label={<Typography variant="subtitle3Bold">{t('live')}</Typography>}
      sx={{
        height: { xs: '20px', sm: '22px' },
        borderRadius: '3px',
        border: '1px solid',
        bgcolor: 'transparent',
        borderColor: 'magenta.600',
        padding: '2px 6px',

        '>.MuiChip-label': {
          p: 0,
          color: 'magenta.600',
          fontWeight: 500,
          fontSize: '12px',
        },
        ...sx,
      }}
      {...props}
    />
  );
};

export default LiveChip;
