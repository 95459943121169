import { useMemo, useState } from 'react';

import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { useRouter } from 'next/router';

import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlayRounded';
import ShareIcon from '@mui/icons-material/Share';
import type { SxProps } from '@mui/material';
import { Box, Typography } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { getMembershipList } from 'api/channelMembership';

import LiveChip from 'components/commons/LiveChip';
import MembersOnlyDialog from 'components/dialogs/MembersOnlyDialog';
import MembershipSignUpDialog from 'components/dialogs/MembershipSignUpDialog';

import useActiveAuth from 'hooks/useActiveAuth';
import useCommonSx from 'hooks/useCommonSx';
import useToggle from 'hooks/useToggle';

import type { ChannelDetailDataType } from 'types/channel';
import type { StatusType } from 'types/matchData';
import type { SportType } from 'types/scoreboardData';

import { dayFormatter } from 'utils/dayFormatter';
import { matchThumbnailPicker } from 'utils/defaultThumbnailPicker';

import MembersOnlyChip from '../../MembersOnlyChip';
import MoreOptionButton from '../../MoreOptionButton';
import ResponsiveTypography from '../../ResponsiveTypography';

const NotAllowedVideoTypeCard = ({
  type,
  channelId: channelIdFromData,
  title,
  createdAt,
  sportType,
  thumbnailUrl: initThumbnailUrl,
  status,
  sx,
}: {
  type: 'match' | 'video' | 'playlist';
  channelId: number;
  title: string;
  createdAt: string;
  sportType?: SportType;
  thumbnailUrl?: string | null;
  status?: StatusType;
  sx?: SxProps;
}) => {
  const { t } = useTranslation('common');
  const { oneLineSx } = useCommonSx();
  const queryClient = useQueryClient();
  const {
    locale,
    query: { handle },
  } = useRouter();
  const { isProfileLoadedUser } = useActiveAuth();
  const {
    isOpen: isOnlyMembersDialogOpen,
    open: openOnlyMembersDialog,
    close: closeOnlyMembersDialog,
  } = useToggle();
  const {
    isOpen: isMembershipSignUpDialogOpen,
    open: openMembershipSignUpDialog,
    close: closeMembershipSignUpDialog,
  } = useToggle();

  const channelId = useMemo(() => {
    // [채널id를 받은 경우] 채널id를 그대로 사용한다.
    if (channelIdFromData) return channelIdFromData;

    // [채널id를 받지 못한 경우] 캐시에서 채널id를 가져온다. 이 컴포넌트가 채널페이지에 있을때만 가능
    const channelDetailDataFromCache =
      queryClient.getQueryData<ChannelDetailDataType>([
        'channelDetailData',
        handle,
      ]);

    // [채널id가 없는 경우, 채널페이지가 아닌 다른페이지에서 이 컴포넌트가 쓰인경우]
    // undefined를 리턴한다 -> 멤버십 가입 다이얼로그가 열리지 않는다.
    // ! 채널페이지가 아니라 다른곳에서도 사용될 경우, 채널id를 추가로 리턴하도록 요청해야함
    return channelDetailDataFromCache?.channelId;
  }, [channelIdFromData, handle, queryClient]);

  const { data: membershipList } = useQuery({
    queryKey: ['membershipPlanList', channelId],
    queryFn: () => getMembershipList(channelId as number),
    staleTime: 1000 * 60 * 5,
    enabled: isProfileLoadedUser && Boolean(channelId),
  });

  const [thumbnailUrl, setThumbnailUrl] = useState(() => {
    if (initThumbnailUrl) return initThumbnailUrl.replace(/\(|\)/g, '\\$&');

    if (type === 'playlist') return '/img/playlist_thumbnail.webp';

    if (type === 'match' && sportType) return matchThumbnailPicker(sportType);
    return '/img/public_matchList/universal_Video_Thumbnail.webp';
  });

  return (
    <>
      <Box
        onClick={openOnlyMembersDialog}
        sx={{
          cursor: { sm: 'pointer' },
          ...sx,
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            aspectRatio: '16 / 9',
            borderRadius: '6px',
            border: '1px solid',
            borderColor: 'divider',
            overflow: 'hidden',
            mb: { xs: 1, sm: 1.5 },

            ':after': {
              content: '""',
              position: 'absolute',
              bottom: '-5%',
              left: '-5%',
              width: '110%',
              height: '110%',
              borderRadius: '6px',
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
            },
          }}
        >
          <Image
            src={thumbnailUrl}
            alt={title}
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            onError={() => {
              let altThumbnailUrl =
                '/img/public_matchList/universal_Video_Thumbnail.webp';
              if (type === 'playlist')
                altThumbnailUrl = '/img/playlist_thumbnail.webp';

              if (type === 'match' && sportType)
                altThumbnailUrl = matchThumbnailPicker(sportType);
              setThumbnailUrl(altThumbnailUrl);
            }}
          />

          {type === 'playlist' ? (
            <Box
              sx={{
                position: 'absolute',
                right: 0,
                width: '50%',
                height: '100%',
                bgcolor: 'rgba(0, 0, 0, 0.5)',
              }}
            >
              <PlaylistPlayIcon
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  color: 'white',
                  width: '36px',
                  height: '36px',
                }}
              />
            </Box>
          ) : null}
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5Bold" sx={{ mb: 0.5, ...oneLineSx }}>
            {title}
          </Typography>

          <MoreOptionButton
            optionList={[
              {
                startIcon: <ShareIcon />,
                text: t('share'),
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onClick: () => {},
                props: { disabled: true },
              },
            ]}
            sx={{ mt: '-4px', mr: '-4px' }}
          />
        </Box>

        <ResponsiveTypography
          xsVariant="body3"
          smVariant="h6"
          sx={{ color: 'text.caption', mb: 0.5 }}
        >
          {`${dayFormatter(createdAt, `YYYY.MM.DD`, {
            locale: locale,
            isZuluTime: true,
          })}・${t(`visibility.membership`)}`}
        </ResponsiveTypography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          <MembersOnlyChip />
          {status === 'live' ? <LiveChip /> : null}
        </Box>
      </Box>

      {isOnlyMembersDialogOpen ? (
        <MembersOnlyDialog
          open={isOnlyMembersDialogOpen}
          onClose={closeOnlyMembersDialog}
          onAction={openMembershipSignUpDialog}
        />
      ) : null}

      {isMembershipSignUpDialogOpen && channelId && membershipList ? (
        <MembershipSignUpDialog
          isOpen={isMembershipSignUpDialogOpen}
          close={closeMembershipSignUpDialog}
          membershipList={membershipList}
        />
      ) : null}
    </>
  );
};

export default NotAllowedVideoTypeCard;
