import { useRef, useState } from 'react';

import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import CopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/DeleteOutlineRounded';
import EditIcon from '@mui/icons-material/Edit';
import LayerIcon from '@mui/icons-material/LayersOutlined';
import LockIcon from '@mui/icons-material/LockOutlined';
import ShareIcon from '@mui/icons-material/Share';
import { CircularProgress } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import { updateRDBToServer } from 'api/RDB/util';
import { deleteStoreMatchData } from 'api/firestore';
import useScoreboardApi from 'api/hooks/useScoreboardApi';

import CustomCircularProgress from 'components/atoms/CustomCircularProgress';
import MoreOptionButton from 'components/commons/MoreOptionButton';

import useActiveAuth from 'hooks/useActiveAuth';
import useClipboardCopy from 'hooks/useClipboardCopy';
import useLoading from 'hooks/useLoading';
import useTanstackQuery from 'hooks/useTanstackQuery';

import type {
  ServerMatchDetailDataType,
  ServerMatchListDataType,
} from 'types/matchData';

const MatchVisibilitySelector = dynamic(
  () => import('components/organisms/MatchVisibilitySelector'),
  { loading: () => <CustomCircularProgress /> },
);
const DeleteDialog = dynamic(
  () => import('components/molecules/DeleteDialog'),
  { loading: () => <CustomCircularProgress /> },
);
const NewEditMatchDialog = dynamic(
  () => import('components/dialogs/NewEditMatchDialog'),
  { loading: () => <CustomCircularProgress /> },
);
const NewCreateMatchDialog = dynamic(
  () => import('components/dialogs/NewCreateMatchDialog'),
  { loading: () => <CustomCircularProgress /> },
);

const MatchCardMenuButton = ({
  isMyPage,
  matchListData,
  thumbnailImg,
}: {
  isMyPage: boolean;
  matchListData: ServerMatchListDataType;
  thumbnailImg: string;
}) => {
  const { getMatchDetailData, deleteMatch } = useScoreboardApi();
  const { token, user, channel } = useActiveAuth();
  const { shareUrl } = useClipboardCopy();
  const router = useRouter();
  const { t } = useTranslation('scoreboard');
  const { resetQueries } = useTanstackQuery();
  const queryClient = useQueryClient();

  const { disclosure, matchUid: matchId } = matchListData;

  const {
    isLoading: isEditPopupLoading,
    loadingStart: editPopupLoadingStart,
    loadingEnd: editPopupLoadingEnd,
  } = useLoading();

  const {
    isLoading: isWidgetPageLoading,
    loadingStart: widgetPageLoadingStart,
    loadingEnd: widgetPageLoadingEnd,
  } = useLoading();

  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isCopyPopupOpen, setIsCopyPopupOpen] = useState(false);
  const [isMatchVisibilitySelectorOpen, setIsMatchVisibilitySelectorOpen] =
    useState(false);
  const [isShowMatchDeleteDialog, setIsShowMatchDeleteDialog] = useState(false);

  const matchDetailDataRef = useRef<ServerMatchDetailDataType>();

  const isMyMatch = user?.id === matchListData.uid;

  const prefetchMatchData = () => {
    queryClient.prefetchQuery({
      queryKey: ['matchData', matchId, channel.channelId],
      queryFn: async () => {
        if (matchId === undefined) return null;
        return await getMatchDetailData(matchId);
      },
      staleTime: 30 * 1000,
    });
  };

  const handleClickEditBtn = async () => {
    try {
      editPopupLoadingStart();
      const updatedMatchData = await updateRDBToServer(
        token,
        matchId,
        user?.id,
      );

      if (updatedMatchData) {
        matchDetailDataRef.current = updatedMatchData;
      } else {
        const gottenMatchData = await getMatchDetailData(matchId);
        matchDetailDataRef.current = gottenMatchData;
      }
      setIsEditPopupOpen(true);
    } catch (error) {
      console.error('Update RDB to server error : ', error);
    } finally {
      editPopupLoadingEnd();
    }
  };

  const handleClickShareBtn = () => {
    shareUrl(
      `${process.env.NEXT_PUBLIC_HOST_URL}/${router.locale}/match/${matchId}`,
    );
  };

  const menuList = [
    {
      text: t(`manage.menu.edit`),
      className: 'gtm-match-edit-btn',
      show: isMyMatch,
      startIcon: isEditPopupLoading ? (
        <CircularProgress size="24px" />
      ) : (
        <EditIcon fontSize="small" />
      ),
      onClick: handleClickEditBtn,
    },
    {
      text: t(`manage.menu.copy`),
      className: 'gtm-match-copy-btn',
      show: isMyMatch,
      startIcon: <CopyIcon fontSize="small" />,
      onClick: () => setIsCopyPopupOpen(true),
    },
    {
      text: t(`manage.menu.disclosure`),
      className: 'gtm-match-disclosure-btn',
      show: isMyMatch,
      startIcon: <LockIcon fontSize="small" />,
      onClick: () => setIsMatchVisibilitySelectorOpen(true),
    },
    {
      text: t(`manage.menu.widget`),
      className: 'gtm-control-btn',
      show: isMyMatch,
      startIcon: isWidgetPageLoading ? (
        <CircularProgress size="24px" />
      ) : (
        <LayerIcon fontSize="small" />
      ),
      onClick: async () => {
        widgetPageLoadingStart();
        await router.push(`/control/${matchId}`);
        widgetPageLoadingEnd();
      },
    },
    {
      text: t(`manage.menu.share`),
      className: 'gtm-match-share-btn',
      show: true,
      startIcon: <ShareIcon fontSize="small" />,
      onClick: handleClickShareBtn,
    },
    {
      text: t(`manage.menu.delete`),
      className: 'gtm-match-delete-btn',
      show: isMyMatch,
      startIcon: <DeleteIcon fontSize="small" />,
      sx: { color: 'error.main', svg: { color: 'error.main' } },
      onClick: () => setIsShowMatchDeleteDialog(true),
    },
  ];

  return (
    <>
      <MoreOptionButton
        className="gtm-match-more-btn"
        onClick={() => prefetchMatchData()}
        optionList={menuList}
        MenuProps={{
          id: `menu ${
            isMyPage
              ? 'gtm-myMatch-kebab-menu-area'
              : 'gtm-match-kebab-menu-area'
          }`,
        }}
        sx={{
          height: 32,
          width: 32,
          mr: '-4px',
          mt: '-4px',
        }}
      />

      {isMyPage ? (
        <>
          {isEditPopupOpen && matchDetailDataRef.current ? (
            <NewEditMatchDialog
              isOpen={isEditPopupOpen}
              matchDataDetail={matchDetailDataRef.current}
              closeDialog={() => setIsEditPopupOpen(false)}
            />
          ) : null}

          {isCopyPopupOpen ? (
            <NewCreateMatchDialog
              isOpen={isCopyPopupOpen}
              originalMatchId={matchId}
              closeDialog={() => setIsCopyPopupOpen(false)}
            />
          ) : null}

          {isMatchVisibilitySelectorOpen ? (
            <MatchVisibilitySelector
              matchId={matchId}
              initVisibility={disclosure}
              initMembershipPlanId={matchListData.membershipPlan?.id}
              open={isMatchVisibilitySelectorOpen}
              onClose={() => setIsMatchVisibilitySelectorOpen(false)}
            />
          ) : null}

          {isShowMatchDeleteDialog ? (
            <DeleteDialog
              type="MATCH"
              open={isShowMatchDeleteDialog}
              close={() => setIsShowMatchDeleteDialog(false)}
              data={{
                thumbnailUrl: thumbnailImg,
                title: matchListData.title,
                createdAt: matchListData.createdAt,
                views: matchListData.views,
              }}
              confirmDelete={async () => {
                await deleteStoreMatchData(matchId);
                try {
                  await deleteMatch(matchId);
                  await resetQueries(['matchList']);
                  enqueueSnackbar(t('deleteMatchDataDialog.complete'), {
                    variant: 'info',
                  });
                } catch (error) {
                  console.error('Sever match data delete error', error);
                  enqueueSnackbar(t('deleteMatchDataDialog.error'), {
                    variant: 'error',
                  });
                }

                setIsShowMatchDeleteDialog(false);
                await router.replace(router.asPath);
              }}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default MatchCardMenuButton;
